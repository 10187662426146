<template>
  <v-container class="pt-0">
    <v-card tile elevation="0" class="px-2">
      <v-list-item
        three-line
        class="pa-0"
        v-for="jenis in item"
        :key="jenis.id"
      >
        <v-list-item-avatar class="mr-3" tile size="75">
          <v-img
            max-width="80"
            eager
            :lazy-src="jenis.img"
            :src="jenis.img"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-card-title
            class="pa-0 ma-0 text-subtitle-2 font-weight-bold black--text"
          >
            {{ jenis.title }}
          </v-card-title>
          <v-card-title
            class="pa-0 ma-0 text-caption grey--text"
            style="line-height: normal"
          >
            {{ jenis.subtitle }}
          </v-card-title>
        </v-list-item-content>

        <v-list-item-avatar class="ml-3" tile width="95" height="85">
          <v-container class="pa-0">
            <v-row class="ma-0" v-show="jenis.btnTmbh">
              <v-col class="pa-0">
                <v-btn
                  x-small
                  dark
                  color="#E84C4F"
                  style="text-transform: unset !important"
                  @click.prevent="addBerat(jenis.id)"
                >
                  Tambah
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="ma-0" v-show="jenis.inputBerat">
              <v-col class="pa-0">
                <v-text-field
                  type="number"
                  readonly
                  dense
                  v-model="jenis.berat"
                  autocomplete="off"
                  suffix="kg"
                >
                  <v-btn
                    class="btnSmall"
                    x-small
                    depressed
                    color="#EB4646"
                    dark
                    slot="append-outer"
                    @click.prevent="plus(jenis.id)"
                  >
                    <v-icon size="13">mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    class="btnSmall"
                    x-small
                    depressed
                    color="#EB4646"
                    dark
                    slot="prepend"
                    @click.prevent="minus(jenis.id)"
                  >
                    <v-icon size="13">mdi-minus</v-icon>
                  </v-btn>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-0" v-show="jenis.inputBerat">
              <v-col class="pa-0">
                <v-btn
                  class="btnSmall"
                  x-small
                  color="#EB4646"
                  dark
                  @click.prevent="clear(jenis.id)"
                >
                  <v-icon size="13">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-list-item-avatar>
      </v-list-item>

      <v-card-actions class="px-0 my-3">
        <v-btn
          block
          large
          :disabled="valid"
          class="white--text"
          color="#EB4646"
          @click.prevent="procced"
          style="text-transform: unset !important"
        >
          <v-row>
            <v-col> Lanjutkan </v-col>
            <v-col> {{ totBerat }} Kg | {{ totItem }} Jenis</v-col>
          </v-row>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "list_katalog",
  data: () => ({
    valid: true,
    totBerat: 0,
    totItem: 0,
    dataSampah: [
      {
        jenis: "",
        berat: "",
      }
    ],
    item: [
      {
        id: "1",
        img: require("@/assets/img/katalog_sampah/semua_jenis_plastik.svg"),
        title: "Semua Jenis Plastik",
        subtitle: "Plastik belanja, kresek, asoy, bungkus mie",
        berat: "",
        btnTmbh: true,
        inputBerat: false,
      },
      {
        id: "2",
        img: require("@/assets/img/katalog_sampah/botol_plastik.svg"),
        title: "Botol Plastik",
        subtitle: "Plastik bening, gula, toples, aqua, dsb",
        berat: "",
        btnTmbh: true,
        inputBerat: false,
      },
      {
        id: "3",
        img: require("@/assets/img/katalog_sampah/jenis_kertas.svg"),
        title: "Jenis Kertas",
        subtitle: "HVS, koran, tempat telur, kardus, LKS",
        berat: "",
        btnTmbh: true,
        inputBerat: false,
      },
      {
        id: "4",
        img: require("@/assets/img/katalog_sampah/jenis_logam.svg"),
        title: "Jenis Logam",
        subtitle: "Minuman kaleng, logam keras, kaleng campur",
        berat: "",
        btnTmbh: true,
        inputBerat: false,
      },
      {
        id: "5",
        img: require("@/assets/img/katalog_sampah/jenis_karah.svg"),
        title: "Jenis Karah",
        subtitle: "Galon, derigen, paralon, tutup botol,toples",
        berat: "",
        btnTmbh: true,
        inputBerat: false,
      },
      {
        id: "6",
        img: require("@/assets/img/katalog_sampah/gelas_plastik.svg"),
        title: "Gelas Plastik",
        subtitle: "Gelas A6B, ale-ale, jelly, gelas plastik campur",
        berat: "",
        btnTmbh: true,
        inputBerat: false,
      },
      {
        id: "7",
        img: require("@/assets/img/katalog_sampah/barang_elektronik.svg"),
        title: "Barang Elektronik",
        subtitle: "Dispenser, printer, CPU, TV, kipas, AC, Hp",
        berat: "",
        btnTmbh: true,
        inputBerat: false,
      },
    ],
  }),
  methods: {
    ...mapActions({
      setDataSampah: "dataOrder/setDataSampah",
    }),
    plus(value) {
      let ar = value - 1;
      this.item[ar].berat++;
      this.totBerat = this.totBerat + 1;
      this.checkValid();
    },
    minus(value) {
      let ar = value - 1;
      if (this.item[ar].berat === "") {
        this.clear(value);
      } else if (this.item[ar].berat === 1) {
        this.clear(value);
      } else {
        this.item[ar].berat--;
        this.totBerat = this.totBerat - 1;
        this.checkValid();
      }
    },
    clear(value) {
      let ar = value - 1;
      this.totBerat = this.totBerat - this.item[ar].berat;
      this.item[ar].berat = "";
      this.item[ar].btnTmbh = !this.item[ar].btnTmbh;
      this.item[ar].inputBerat = !this.item[ar].inputBerat;
      this.totItem--;
      this.checkValid();
    },
    checkValid() {
      if (this.totBerat >= 10) {
        this.valid = false;
      } else {
        this.valid = true;
      }
    },
    addBerat(value) {
      let ar = value - 1;
      this.item[ar].berat = 1;
      this.totBerat++;
      this.item[ar].btnTmbh = !this.item[ar].btnTmbh;
      this.item[ar].inputBerat = !this.item[ar].inputBerat;
      this.totItem++;
      this.checkValid();
    },
    procced() {
      var data = []
      this.item.forEach((value) => {
        if (value.berat != "") {
          data.push({jenis: value.title,berat: value.berat});
        }
      });
      this.setDataSampah(data);
      this.dataSampah = data;
      this.$router.push({ name: "Order" });
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
  },
};
</script>

<style>
.btnSmall {
  min-width: 0 !important;
  width: 16px !important;
  min-height: 0 !important;
  height: 16px !important;
}

.v-input {
  font-size: 10.5px !important;
}
</style>