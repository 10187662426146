<template>
  <v-container class="pa-0">
    <v-list-item class="py-0">
      <v-list-item-avatar size="28" class="mr-0">
        <v-btn text small color="#232424" @click.prevent="back">
          <v-icon size="20"> mdi-arrow-left </v-icon>
        </v-btn>
      </v-list-item-avatar>
      <v-list-item-content class="pa-0">
        <logo-top />
      </v-list-item-content>
      <v-list-item-avatar tile width="auto">
        <v-btn
          class="font-weight-bold"
          text
          color="#0C84F3"
          small
          style="text-transform: unset !important"
          @click.prevent="katalog"
        >
          <v-icon small left> mdi-arrow-collapse-down </v-icon>Katalog Sampah
        </v-btn>
      </v-list-item-avatar>
    </v-list-item>
    <top />
    <list-katalog />
  </v-container>
</template>

<script>
import Top from "../components/JualSampah/Top.vue";
import ListKatalog from "@/components/JualSampah/ListKatalog.vue";
import LogoTop from "../components/Home/LogoTop.vue";

export default {
  components: { Top, ListKatalog, LogoTop },
  metaInfo: {
    title: "Pemol",
    titleTemplate: "%s - Katalog",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },
  name: "katalog_sampah",
  data: () => ({
    link: "http://shorturl.at/nwST4",
  }),
  methods: {
    back() {
      this.$router.push({ name: "Home" });
    },
    katalog() {
      window.open(
        this.link,
        "_blank"
      );
    }
  },
};
</script>